import React, {useState} from 'react';
import styles from "./Navbar.module.scss";
import {MdLocalShipping} from "react-icons/md";

const Navbar = (props) => {
    const [navigationVisible, setNavigationVisible] = useState(false);
    const toggleNavigation = () => {
        setNavigationVisible(prev => !prev)
    }

    return (
        <div className={styles.navbar}>
            <nav className={`${styles.wrapper} ${navigationVisible ? styles.active : ""}`}>
                <div className={styles.logo}>
                    <MdLocalShipping className={styles.icon}/>
                    <h4>ŞAFAK NAKLİYAT</h4>
                </div>
                <ul className={`${styles.links} ${navigationVisible ? styles.active : ""}`}>
                    <li>
                        <a href={`/`}>
                            Anasayfa
                        </a>
                    </li>
                    <li>
                        <a href={`/iletisim`}>
                            İletişim
                        </a>
                    </li>
                </ul>
                <div className={`${styles.burger} ${navigationVisible ? styles.active : ""}`}
                     onClick={toggleNavigation}>
                    <div className={styles.line1}/>
                    <div className={styles.line2}/>
                    <div className={styles.line3}/>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;