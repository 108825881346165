import React from 'react';
import styles from "./ImageSection.module.scss";
import {Slide} from "react-slideshow-image";
import Image1 from "../../../images/1.jpeg";
import Image2 from "../../../images/2.jpeg";
import Image3 from "../../../images/3.jpeg";
import Image4 from "../../../images/4.jpeg";
import Image5 from "../../../images/5.jpeg";

const ImageSection = (props) => {
    const images = [
        {src: Image1, context: "1. Görsel"},
        {src: Image2, context: "2. Görsel"},
        {src: Image3, context: "3. Görsel"},
        {src: Image4, context: "4. Görsel"},
        {src: Image5, context: "5. Görsel"},
    ]
    const properties = {
        duration: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssClass: styles.wrapper,
        autoplay: true,
        indicators: true,
        canSwipe: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    return (
        <div className={styles.slideContainer}>
            <Slide {...properties}>
                {images.map((image, index) => (
                    <div className={styles.eachFade} key={image.context}>
                        <div>
                            <img src={image.src} alt={"image"}/>
                        </div>
                    </div>
                ))}
            </Slide>
        </div>
    );
};

export default ImageSection;