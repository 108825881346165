import React, {Fragment} from 'react';
import styles from "./AboutSection.module.scss";
import Card1 from "../../../images/card1.png"
import Card2 from "../../../images/card2.png"
import Card3 from "../../../images/card3.png"
import Card4 from "../../../images/card4.png"
import Card5 from "../../../images/card5.png"
import {AiOutlineMail, AiOutlinePhone} from "react-icons/ai";

const AboutSection = (props) => {
    const cards = [
        {
            header: "Ekspertiz Hizmetleri",
            text: "Evden eve nakliyat, ofis ve büro nakliyesi, kurumsal taşıma gibi tüm nakliye işlemlerinizde Şafak Nakliyat, alanında uzman eksperleriyle taşıma bölgelerinde ön inceleme yapar.",
            image: Card1
        },
        {
            header: "Eşya paketleme Hizmetleri",
            text: "Paketleme, evden eve nakliyat hizmetlerinde en önemli aşamalardandır. Şafak Nakliyat tarafından görevlendirilen bu alanda uzman personel, eşyalarınızın darbeler ve taşıma sırasında yaşanabilecek olan sarsıntılarda hasar almamasını sağlamak adına eşyalarınızı uygun bir biçimde paketlemektedirler.",
            image: Card2
        },
        {
            header: "Asansörlü Taşıma",
            text: "Asansörlü Nakliyat, pratik yaşamın ayrılmaz bir parçası haline gelmiştir. Daha hızlı ve daha güvenli bir taşıma modelidir. Şafak Nakliyat olarak sizlere daha hızlı bir taşınma seçeneği sunuyoruz.",
            image: Card3
        },
        {
            header: "Montaj/Demontaj",
            text: "Tüm mobilyalar, uzman mobilyacılarımız tarafından demonte edilerek sökülür, yeni evinizde belirlediğiniz konumlara istediğiniz şekilde yerleştirilerek monte edilir.",
            image: Card4
        },
        {
            header: "Sigortalı Taşıma Hizmetleri ",
            text: "Şafak Evden Eve Nakliyat olarak bizimle yapacağınız tüm nakliye işlemlerinizde eşyalarınız sigorta kapsamında güvence altına alınır.",
            image: Card5
        }
    ]

    return (
        <Fragment>
            <div className={styles.aboutUsWrapper}>
                <div className={styles.aboutUs}>
                    <h1>ŞAFAK NAKLİYAT</h1>
                    <span>33 yıllık profesyonel deneyimiyle eşyalarınız güvenle taşınır. Ev eşyalarınız profesyonel ekip tarafından
            sökülür ambalajlanır ve araca asansörle yüklenir. Taşıma işlemi bittikten sonra ambalajdan çıkarılıp tekrar
            kurulum işlemi yapılır.
                    </span>
                </div>
                <div className={styles.phoneSection}>
                    <h1> İletişim Numaraları:</h1>
                    <div className={styles.phone}>
                        <AiOutlinePhone/> <a href="tel:+90(532)-427-78-86">+90 (532) 427 78 86</a>
                    </div>
                    <div className={styles.phone}>
                        <AiOutlinePhone/> <a href="tel:+90(541)-427-78-86">+90 (541) 427 78 86</a>
                    </div>
                </div>
            </div>

            <h1 className={styles.sectionHeader}>Taşıma & Depolama Hizmetleri</h1>
            <div className={styles.wrapper}>
                {cards.map(card => (
                    <div className={styles.card}>
                        <img src={card.image} alt=""/>
                        <h1>{card.header}</h1>
                        <span>{card.text}</span>
                    </div>
                ))}
            </div>
        </Fragment>
    );
};

export default AboutSection;