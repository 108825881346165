import styles from './App.module.scss';
import Navbar from "./components/Navbar/Navbar";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import 'react-image-lightbox/style.css';
import 'react-slideshow-image/dist/styles.css'
import Home from "./components/Home/Home";
import Contact from "./components/Contact/Contact";

const App = () => {

    return (
        <div  className={styles.app}>
            <Router>
                <Navbar/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/iletisim" element={<Contact/>}/>
                </Routes>
            </Router>
        </div>
    );
}

export default App;
