import React, {createRef, Fragment, useEffect, useRef, useState} from 'react';
import styles from "./Home.module.scss";
import ImageSection from "./ImageSection/ImageSection";
import AboutSection from "./AboutSection/AboutSection";
import {AiOutlinePhone} from "react-icons/ai";

const Home = (props) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.phones}>
                <div className={styles.phone}>
                    <a href="tel:+90(532)-427-78-86">
                        <div className={styles.iconWrapper}>
                            <AiOutlinePhone/>
                            <div className={styles.number}>1</div>
                        </div>
                    </a>
                </div>
                <div className={styles.phone}>
                    <a href="tel:+90(541)-427-78-86">
                        <div className={styles.iconWrapper}>
                            <AiOutlinePhone/>
                            <div className={styles.number}>2</div>
                        </div>
                    </a>
                </div>
            </div>
            <AboutSection/>
            <ImageSection/>
        </div>
    )
        ;
};

export default Home;