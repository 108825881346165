import React from 'react';
import styles from "./Contact.module.scss";
import {AiOutlineMail, AiOutlinePhone} from "react-icons/ai";
import {BsFacebook, BsInstagram, BsLinkedin} from "react-icons/bs";
import {GrMapLocation} from "react-icons/gr";

const Contact = (props) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.addressSection}>
                <div className={styles.address}>
                    <h1 className={styles.header}>Adres</h1>
                    <span className={styles.content}> <GrMapLocation/>Mimar Sinan Mahallesi 6043. sokak No:7 07100 Manavgat/Antalya </span>
                </div>
                <div className={styles.mapWrapper}>
                    <iframe className={styles.map} loading="lazy" allowFullScreen
                            src="https://www.google.com/maps/embed/v1/place?q=36.7914047420955%2C%2031.41811622574301&key=AIzaSyD-rcgxQeVOKnetiKUAsuhEsCxUzny8xJU"/>
                </div>
            </div>
            <div className={styles.contact}>
                <h1 className={styles.context}> İletişim Numaraları:</h1>
                <div className={styles.phone}>
                    <AiOutlinePhone/> <a href="tel:+90(532)-427-78-86">+90 (532) 427 78 86</a>
                </div>
                <div className={styles.phone}>
                    <AiOutlinePhone/> <a href="tel:+90(541)-427-78-86">+90 (541) 427 78 86</a>
                </div>
                <h1 className={styles.context}> Mail Adresimiz:</h1>
                <div className={styles.mailAddress}>
                    <AiOutlineMail/> <a href="mailTo: abdurrahmansfk@hotmail.com"> abdurrahmansfk@hotmail.com </a>
                </div>
                <div className={styles.orWrapper}>
                    <hr/>
                    <span>Sosyal Medya Hesaplarımız</span>
                    <hr/>
                </div>
                <div className={styles.socialMedias}>
                    <div className={styles.media}>
                        <a href={"https://www.facebook.com/profile.php?id=100008628754223"}> <BsFacebook/></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;